import moment from "moment";
import { DateTime } from "luxon";

const dutch = {
  vignet: "vignet",
  squareMeters: "vierkante meter",
  required: "Verplicht",
  emailRequired: "Het veld e-mailadres is niet ingevuld",
  passwordRequird: "Het veld wachtwoord is niet ingevuld",
  invalidEmail: "Het e-mailadres in het veld hierboven is onjuist ingevuld",
  emailMatch: "E-mailadres moet overeenkomen",
  // footer
  contact: "Contact",
  "footer-first-paragraph":
    "Hebt u een vraag of hebt u ondersteuning nodig bij het invullen van dit formulier?",
  "footer-second-paragraph": "Neem dan contact op met de gemeente.",
  "footer-third-paragraph":
    "Gemeentelijk informatienummer: 14 020 op werkdagen van 08.00 tot 18.00 uur.",
  "footer-fourth-paragraph": "Of stuur een e-mail via het ",
  "go-to-bhg": "Ga naar Amsterdam.nl",
  mailForm: "mailformulier",
  privacy: "Privacy",
  country: "Land",

  vignetPin: "Vignet pin",
  vignetteNumber: "Vignetnummer",
  repeatVignetNumber: "",
  openHours: "Openings tijden",
  monday: "Maandag",
  tuesday: "Dinsdag",
  wednesday: "Woensdag",
  thursday: "Donderdag",
  friday: "Vrijdag",
  saturday: "Zaterdag",
  sunday: "Zondag",
  closed: "Gesloten",
  delivery: "Bezorging",
  insertion: "Tussenvoegsel",
  telephone: "Telefoonnummer",
  email: "E-mailadres",
  resume: "Samenvatting",
  resumeDetails:
    "Van uw aanvraag ontvangt u een bevestigingsmail in uw mailbox. U kunt ook de samenvatting downloaden. ",
  totalAmount: "Totaalbedrag",
  total: "Kosten",
  totalVignette: "Subtotaal",
  payment: "Betaling",

  // steppers
  step1: "Uw gegevens",
  step2: "Vignetoverzicht",
  step3: "Uw vignet",
  step4: "Samenvatting",
  step5: "Bedankt",

  // login screen
  welcome: "Welkom",
  "login-info":
    "Met dit formulier kunt u uw binnenhavengeldvignet wijzigen, opzeggen of verlengen. U moet hiervoor inloggen in uw account.",
  login: "Inloggen",
  "email-address": "E-mailadres",
  password: "Wachtwoord",
  "forgot-password": "Wachtwoord vergeten",
  "forgot-message":
    "Bent u uw wachtwoord vergeten? Vul dan hieronder uw e-mailadres in. U krijgt binnen enkele minuten een e-mail waarmee u een nieuw wachtwoord kunt aanmaken.",
  send: "Versturen",
  resetFeedback:
    "Bedankt! U ontvangt binnen enkele minuten een e-mail waarmee u een nieuw wachtwoord kunt aanmaken.",
  backToLogin: "Terug naar inloggen",
  newPassword: "Nieuw wachtwoord aanmaken",
  passwordInstruction:
    "Vul hieronder uw nieuwe wachtwoord in. Het wachtwoord moet aan deze eisen voldoen:",
  pwInstcution1: "minimaal 8 karakters",
  pwInstcution2: "tenminste 2 cijfers",
  pwInstcution3: "tenminste 1 hoofdletter",
  pwInstcution4: "maximaal 24 karakters",
  retypePassword: "Herhaal wachtwoord",
  passwordMatch: "wachtwoorden moeten overeenkomen",
  passwordFailedValidation: "Wachtwoord komt niet overeen met validatie",
  setPasswordChanged: "Wachtwoord gewijzigd",
  setPasswordChangedMessage:
    "Gelukt! U hebt uw wachtwoord succesvol gewijzigd. U kunt nu opnieuw inloggen.",

  // personal info
  logout: "Uitloggen",
  personalInfoHeader: "Vaarvignetten",
  personalInfoTitle: "Uw persoonlijke gegevens",
  personalInfoSubTitle: "Dit zijn uw gegevens die bekend zijn in ons systeem. ",
  initials: "Uw voorletters",
  initialsRequired: "Het veld Uw voorletters is niet ingevuld",
  lastname: "Achternaam",
  lastnameRequired: "Het veld achternaam is niet ingevuld",
  contactDetails: "Uw contactgegevens",
  detailsInfo:
    "Hieronder ziet u de contactgegevens die wij van u hebben. Kloppen de gegevens niet? Dan kunt u deze wijzigen.",
  change: "wijzig",
  street: "Straat",
  streetRequired: "Het veld straat is niet ingevuld",
  bsn: "BSN",
  bsnRequired: "Het veld BSN is niet ingevuld",
  houseNumberRequired: "Het veld huisnummer is niet ingevuld",
  houseNumber: "Huisnummer",
  addition: "Toevoeging",
  notRequired: "niet verplicht",
  postcode: "Postcode",
  postCodeRequired: "Het veld postcode is niet ingevuld",
  residence: "Woonplaats",
  residenceRequired: "Het veld woonplaats is niet ingevuld",
  phone: "Telefoonnummer",
  phoneRequired: "Het veld telefoonnummer is niet ingevuld",
  phoneNumberValidation: "Telefoonnummer mag alleen uit cijfers bestaan",
  repeatEmail: "Herhaal e-mailadres",
  repeatEmailRequired: "Het veld herhaal e-mailadres is niet ingevuld",
  companyDetails: "Bedrijfsgegevens",
  chamberCommerce: "KVK-nummer",
  company: "Bedrijfsnaam",
  contactPerson: "Contactpersoon",
  previous: "Vorige",
  next: "Volgende",
  BSN: "BSN",
  sendNow: "Nu verzenden",

  // vignet overview decision
  vignetOverview: "Vignetoverzicht",
  vignetOverviewDescription:
    "Hieronder ziet u de boten die op uw naam staan en de vignetten die daarbij horen. U kunt per boot aangeven of u wilt wijzigen en verlengen of wilt opzeggen.",
  boatName: "Naam van uw boot",
  vignetNumber: "Vignetnummer",
  renewCancel: "Wijzigen/ verlengen of opzeggen",
  renew: "Wijzigen/ verlengen",
  smallRenew: "verlengd",
  cancel: "Opzeggen",
  smallCancel: "opgezegd",
  noAction: "Geen actie",
  makeChoiceAbove:
    "U hebt hierboven nog geen keuze gemaakt, het maken van een keuze is verplicht",
  selectChoice:
    "U hebt hierboven nog geen keuze gemaakt, het maken van een keuze is verplicht",
  mustAgree: "Vink het vakje aan om akkoord te gaan",

  // cancel vignet
  cancelVignetdescription:
    "Hieronder ziet u uw vignet dat u wilt opzeggen. Geef ook aan waarom u het wilt opzeggen.",
  cancelReason: "Reden opzeggen",
  vesselSold: "Boot verkocht",
  vignetLost: "Vignet is zoek geraakt",
  vignetStolen: "Vignet is gestolen",
  others: "Anders namelijk:",
  cancellationAgreement:
    "Ik ga ermee akkoord dat het vignet dat hierboven staat wordt opgezegd. Ik begrijp dat ik dan met dit vignet niet meer mag varen en afmeren in Amsterdam.",
  cancellationRequired:
    "U hebt hierboven het akkoord voor de voorwaarden niet aangevinkt, het aanvinken is verplicht",

  // overview screen
  summaryDetail:
    "Hieronder vindt u een overzicht van uw gegevens. Controleer de gegevens goed. U kunt nog wijzigingen doorvoeren als er iets niet klopt.",
  boatname: "Naam boot",
  checkbox1:
    "Ik heb alle gegevens correct en volledig ingevuld. Ik begrijp dat een aanvraag via internet dezelfde juridische status heeft als een aanvraag met een geschreven geldige handtekening.",
  checkbox2:
    "Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart en de Verordening op het binnenwater (te vinden op amsterdam.nl/varen). Bij het verlengen of opzeggen van mijn vignet ga ik met deze regels akkoord.",
  unsubscribeReason: "Reden afmelden",
  verifyInfo: "Controleer uw gegevens",

  // download screen
  downloadSubTitle: `Hartelijk dank voor uw wijziging, opzegging of verlenging. Wij hebben uw melding ontvangen op ${DateTime.now()
    .setLocale("nl")
    .toLocaleString(DateTime.DATE_FULL)} om ${moment().format(
    "HH.mm"
  )} uur. U krijgt binnen 4 werkdagen via e-mail een bevestiging van ons.`,
  downloadDetail:
    "Van uw aanvraag ontvangt u een bevestigingsmail in uw mailbox. U kunt ook de samenvatting downloaden. ",
  download: "Downloaden ",
  ask: "Vragen",
  finalDetail:
    "Bel dan 14 020 of gebruik het contactformulier op de website. Uw vignet heeft een vignetnummer. Bij contact kunt u dit vignetnummer noemen.",

  // extend vignette
  extendTitle: "Wijzigen, verlengen en betalen",
  extendDescription:
    "Hieronder ziet u de gegevens van het vignet dat u wilt wijzigen of verlengen.",
  vignetDetails: "Gegevens van uw boot",
  boatLength: "Lengte boot",
  boatWidth: "Breedte boot",
  length: "Lengte",
  lengthRequired: "Het veld lengte is niet ingevuld",
  width: "Breedte",
  widthRequired: "Het veld breedte is niet ingevuld",
  drive: "Aandrijving",
  marina: "Jachthaven/ gepacht water/ buiten Amsterdam",
  selectDrive: "Selecteer Aandrijving",
  yes: "Ja",
  no: "Nee",
  vignetCalculation: "Vignet berekening",
  vignetType: "Type vignet",
  totalArea: "Totaal oppervlakte m2",
  totalCost: "Totaal kosten",
  paymentMethod: "Betaalmethode",
  paymentMethod2: "Betaalwijze",
  paymentInstruction:
    "U kunt het bedrag in 1 keer betalen via iDEAL of in 4 delen. Kiest u voor betalen in 4 delen? Dan betaalt u via automatische incasso. Het eerste deel van het bedrag wordt binnen 48 uur van uw rekening afgeschreven. De volgende 3 betalingen volgen elke maand daarop.",
  pay: "Betalen",
  accountNumber: "Rekeningnummer",
  accountNumberRequired: "Het veld rekeningnummer is niet ingevuld",
  accountNumberInvalid:
    "Het rekeningnummer in het veld hierboven is onjuist ingevuld",
  accountHolder: "Naam rekeninghouder",
  accountHolderRequired: "Het veld rekeninghouder is niet ingevuld",
  chooseBank: "Kies uw bank",
  selectBank: "Selecteer uw bank",
  bankPaymentErrorTitle: "Uw betaling is mislukt",
  bankPaymentErrorDescription:
    "Het vignet is niet verlengd. Wijzig uw betaalmethode en probeer het nog een keer.",
  documents: "Documenten",
  NononTheFingHeader: "Voeg hier uw documenten toe",
  NononTheFingDescription: `Ligt uw boot in een jachthaven? Upload de jachthavenfactuur. Op de factuur moet de naam en het adres van de jachthaven staan. En uw eigen adres moet op de factuur staan. {br}{br}
  Ligt uw boot in van de gemeente gepacht water in Amsterdam? Upload een geldig erfpachtcontract. {br}{br} 
  Ligt uw boot buiten Amsterdam? Upload een foto van de ligplaats met de afgemeerde boot. Upload ook een kaart van de locatie.`,
  NononTheFingHeader2:
    "Voeg hier een foto toe van uw boot waarop het vignet zichtbaar is.",
  NononTheFingDescription2:
    "Als uw boot gestolen is kunnen wij deze sneller herkennen.",
  onThefingHeader: "Voeg document ontheffing toe",
  onThefindDescription:
    "Is uw boot 10 meter of langer? Dan moet u hiervoor een ontheffing of vrijstelling hebben. Deze ontheffing moet u hier uploaden.",
  uploadText: "Sleep de bestanden in dit vlak of",
  selectFiles: "selecteer bestanden",
  marinaQuestion:
    "Ligt uw boot in een jachthaven, gepacht water of buiten Amsterdam?",
  documentUpload: "Document geüpload",
  cancelUpload: "Annuleren",
  milieuTitle: "Let op: u krijgt een milieu (groen) vignet.",
  milieuDescription:
    "Uit uw gegevens blijkt dat u recht hebt op een milieu (groen) vignet. Dit vignet is alleen geldig als uw boot een elektrische motor of helemaal geen motor heeft.",
  hybridTitle: "Let op: u krijgt een hybride (geel) vignet.",
  hybridDescription:
    "Uit uw gegevens blijkt dat u recht heeft op een hybride (geel) vignet. Dat betekent dat uw boot een elektrische motor én een diesel- of benzinemotor heeft. Vaart u op het Amsterdamse binnenwater? Dan mag u alleen de elektrische motor gebruiken. U mag uw elektrische motor tijdens het varen niet opladen met een (brandstof) generator.",
  abonmentTitle: "Let op: u krijgt een abonnement (wit) vignet.",
  abonmentDescription:
    "Uit uw gegevens blijkt dat u recht hebt op een abonnement (wit) vignet. Dat betekent dat uw boot in een jachthaven of buiten Amsterdam ligt. Met dit vignet mag u tussen 23.00 uur en 06.00 uur niet afmeren op het Amsterdamse binnenwater.",
  regulierTitle: "Let op: u krijgt een regulier (rood) vignet.",
  regulierDescription:
    "Uit uw gegevens blijkt dat u recht hebt op een regulier (rood) vignet. Dat betekent dat uw boot een diesel- of benzinemotor heeft. Met dit vignet mag u varen en afmeren op het Amsterdamse binnenwater. Vanaf 2025 mag u in het centrum van Amsterdam alleen nog maar uitstootvrij varen.",
  accountNumberAgreement:
    "Ik geef de gemeente Amsterdam toestemming om de kosten van mijn vignet in 4 termijnen van mijn bankrekening af te schrijven.",
  requiredDocument: "U moet het vereiste document uploaden",
  toClear: "Wissen",
  mustSelectDrive: "U moet een schijftype selecteren",
  paymentSuccessfulTitle: "Uw betaling is succesvol afgerond",
  paymentSuccessfulDescription:
    "Het vignet is verlengd. Hebt u meerdere vignetten? Dan gaat u automatisch terug naar het scherm met uw overzicht.",
  vesselType: "Soort vaartuig",
  boatName2: "Boot naam",

  // TRANSIT VIGNETTES
  //   home page
  homePageTitle: "Doorvaartvignet",
  homePageSubTitle: "Doorvaartvignet Gemeente Amsterdam",
  homePageDescription: `Om in Amsterdam te kunnen varen moet u een geactiveerd vignet op uw boot {br}
  hebben. Dat kan een Binnenhavengeld-vignet zijn. Heeft u deze niet, dan heeft {br}
  u een Doorvaartvignet nodig.`,
  transitEmailRequired:
    "Dit is geen geldig e-mailadres. Een e-mailadres bestaat uit @ en een domeinnaam. Bijvoorbeeld: naam@domein.nl",
  transitEmailNoMatch: "De e-mailadressen zijn niet hetzelfde.",
  transitTelephoneNoMatch: "De telefoonnummers zijn niet hetzelfde",
  transitVignetteNoMatch: "De vignetnummers zijn niet hetzelfde",
  transitAgreeMessage:
    "U moet akkoord gaan met de verklaring. Vink aan als u akkoord gaat.",
  transitPaymentFailedTitle: "De betaling is mislukt.",
  transitPaymentFailedDescription:
    "Probeer het opnieuw of kies een andere manier om te betalen.",

  homePageActivateVignetTitle: "Vignet activeren",
  homePageActivateVignetDescription: `Hebt u al een doorvaartvignet? {br} Dan kunt u hier uw vignet activeren.`,
  homePageActivateButton: "Activeer uw vignet",

  homePageRequestVignetTitle: "Nieuw vignet aanvragen",
  homePageRequestVignetDescription: `Hebt u uw Doorvaartvignet al? {br}
  Vraag er een aan zodat u in Amsterdam kunt varen.`,
  homePageRequestButton: "Vraag een nieuw vignet aan",

  homePageMoorVignetTitle: "Vignet voor 24 uur afmeren",
  homePageMoorVignetDescription: `Hebt u een doorvaartvignet, maar wilt u toch afmeren? {br}
  Vraag dan 24 uur afmeren aan. Deze is direct geldig na aanschaf`,
  homePageMoorButton: "Vraag 24 uur afmeren aan",

  // --- request day vignette ----
  dayVignetteTitle: "24 uur afmeren kopen of activeren",
  dayStep1: "Nummer vignet invoeren",
  dayStep2: "Product voor 24 uur afmeren",
  dayStep3: "Contactgegevens",
  dayStep4: "Betalen",
  dayStep5: "Samenvatting",
  dayStep6: "Bedankt",

  transitDownloadSubTitle: `U ontvangt een e-mail met een bevestiging van uw betaling. {br} U kunt de samenvatting van uw aanvraag hieronder downloaden. {br} Deze wordt niet per mail verstuurd.`,
  transitFinalDetail: `Bel het telefoonnummer 14 020, maandag tot en met vrijdag van 08.00 tot {br} 18.00 uur. Of gebruik het contactformulier op de website. {br} {br}
  Wij hebben een nummer gegeven aan uw aanvraag.{br}
  Het nummer is: {vignet}. Bij contact kunt u dit nummer noemen.`,

  // vignet verification
  dayVignetteVerificationTitle: "Nummer Doorvaartvignet invoeren",
  dayVignetteDescription: `U kunt hier ‘24 uur afmeren’ activeren of kopen.`,
  dayVignetteNumber: "Nummer van uw Doorvaartvignet",
  repeatvignetteNumber: "Herhaal nummer van uw vignet",

  // payment guide
  dayVignettePaymentGuideTitle: "24 uur afmeren",
  dayVignettePaymentGuideDescription: `Na het ontvangen van de bevestingsmail mag u {br}
  24 uur afmeren in de gemeente Amsterdam.`,
  dayVignettePaymentGuideItem1:
    "24 uur afmeren is per direct en 24 uur geldig.",
  dayVignettePaymentGuideItem2: "De kosten zijn € 30,-.",
  dayVignettePaymentGuideInfo: `Hebt u een voucher dan kunt u hiermee betalen.`,

  // personal info
  dayVignettePersonalDescription: `Vul hier uw contactgegevens in, zodat u een bevestiging ontvangt.`,
  repeatTelephone: "Herhaal Telefoonnummer",
  dayVignetteFirstName: "Voornaam",

  // payment choice
  dayVignettePaymentChoiceTitle: "Kies betaalmethode",
  dayVignettePaymentChoiceDescription: `U kunt het bedrag betalen via iDEAL of Creditcard.`,

  // summary
  dayVignetteSummaryDescription:
    "Hieronder staan uw gegevens voor 24 uur afmeren.",
  dayVignettePaymentGuideLabel: "24 uur afmeren",
  dayVignettePaymentGuideLabel1: "Geldig vanaf",
  dayVignettePaymentGuideLabel2: "Geldig tot",

  // new vignette
  newVignetteTitle: "Nieuw vignet aanvragen",
  newVignetteStep1: "Vignet kiezen",
  newVignetteStep2: "Gegevens invullen",
  newVignetteStep3: "Bezorgopties",
  newVignetteStep4: "Betalen",
  newVignetteStep5: "Samenvatting van uw aanvraag",
  newVignetteStep6: "Bedankt",

  // choose vignette
  newVignetteChooseTitle: "Maak uw keuze",
  newVignetteChoseVignetteDescription: `U kunt hier een Doorvaartvignet voor Amsterdam kopen. {br} Hieraan zit eenmalig '24 uur afmeren' gekoppeld. {br} Deze kunt u direct of later activeren en gebruiken. {br} {br} Let op: is uw boot langer dan 10 meter, dan mag u niet afmeren.`,
  newVignetteChooseVignetteSort: "Vignetsoort",
  newVignetteChooseVignetteSortLabel1: `Doorvaar vigent <b>zonder</b> aanleg optie € 40,-`,
  newVignetteChooseVignetteSortLabel2: `Doorvaar vigent <b>met</b> aanleg optie € 50,-`,
  newVignetteChooseVignetteDropDownLabel: "Aantal vignetten",
  newVignetteChooseVignetteDropDownDescription:
    "U kunt maximaal 10 vignetten aanvragen (alle met of alle zonder 24 uur afmeren).",
  newVignetteChoseVignetteInfoLabel: "Let op: Alleen post verzending mogelijk",
  newVignetteChoseVignetteInfoDescription1:
    "Als u meer dan 1 vignet aanvraagt verzenden wij deze altijd per gewone post.",
  newVignetteChoseVignetteInfoDescription2:
    "Afhalen of spoedbezorgen is niet mogelijk.",

  // personal info
  newVignettePersonalInfoTitle: "Uw persoonlijke gegevens",
  newVignettePersonalInfoDescription: "Vul alstublieft uw gegevens in.",
  newVignettePersonalInfoCheckboxLabel:
    "Ik vraag een vignet aan namens een bedrijf.",

  // delivery options
  newVignetteDeliveryOptionsTitle: "Bezorgopties",
  newVignetteDeliveryOptionsTakeAway: "Afhalen",
  newVignetteDeliveryOptionsSubtitle: "Korte toelichting 4 termijnen",
  newVignetteDeliveryOptionsHomeTitle: "Thuisbezorgd",
  newVignetteDeliveryOptionsCollectionPoints: "Afhaalpunten (gratis)",
  newVignetteDeliveryOptionsCollectionLabel: `<b> Ik haal mijn vignet zelf op bij een van onderstaande afhaalpunten. </b>`,
  newVignetteDeliveryOptionsCollectionsMore: `U ontvangt een betalingsbewijs. Daarmee kunt u direct op een {br} van onderstaande adresen uw vignet ophalen. U hoeft {br} hiervoor geen afspraak te maken.`,
  newVignetteDeliveryOptionsCharge: "Bezorgkosten",

  // summary
  newVignetteSummaryDescription: `Wij hebben uw aanvraag ontvangen op ${moment()
    .format("DD MMMM YYYY")
    .toLowerCase()}.`,
  newVignetteSummaryInfo: `U ontvangt een e-mail met de bevestiging van het 24 uur afmeren.
  {br}
  24 uur afmeren is direct geldig. U kunt de aanvraag hieronder downloaden.
  {br}{br}
  1 uur van tevoren krijgt u een sms dat ‘24 uur afmeren’ bijna verloopt.`,
  newVignetteTransitFinalDetail: `Bel het telefoonnummer 14 020, maandag tot en met vrijdag van 08.00 tot {br} 18.00 uur. Of gebruik het contactformulier op de website. {br} {br}
  Houd uw vignetnummer {vignet} bij de hand`,

  // activate vignette
  activateVignetteStep1: "Nummer vignet invoeren",
  activateVignetteStep2: "Uw gegevens",
  activateVignetteStep3: "Gegevens van uw boot",
  activateVignetteTitle: "Vignet activeren",
  activateVignetteSubtitle: "Vignetnummer en activatiecode invullen",
  activateVignetteChoices: "Kies vignettype",
  activateVignetteChoiceLabel1:
    "Doorvaartvignet aangeschaft bij de Gemeente Amsterdam.",
  activateVignetteChoiceLabel2: "Vignet van een andere gemeente of provincie.",
  activateVignetteDescription1: `Vul hieronder het nieuwe vignetnummer en de activatiecode in. {br}
  U vindt het vignetnummer op het fysieke vignet. {br} 
  U hebt de activatiecode in een e-mail ontvangen.`,
  activateVignetteDescription2: `Vul hieronder het  vignetnummer in. {br} U vindt het vignetnummer op het fysieke vignet.`,
  activateVignetteCode: "Activatiecode",
  activateVignetteFormDetails: `Heeft u een Vignetten van buiten Gemeente Amsterdam? 
    {br} Vul dan bij vignet pin nogmaals uw Vignetnummer in.`,
  activateVignetteNotificationTitle:
    "Graag uw Amsterdamse vignetten van 2019 of eerder van uw boot verwijderen.",
  activateVignetteNotificationDescription: `Heeft u een vignet van een andere gemeente of provincie. 
    {br} Vul dan twee keer uw Vignetnummer in.`,
  vignettePrivacyStatement: `De gemeente Amsterdam gaat zorgvuldig en veilig met uw persoonsgegevens om. Wij houden ons daarbij aan de privacy-  wetten en -regels. Dat betekent bijvoorbeeld dat wij uw gegevens alleen voor deze aanvraag gebruiken en niet voor iets  anders. Meer informatie vindt u in de <link>privacyverklaring Binnenhavengeld</link>.`,

  // personal info
  activateVignettePersonalSubtitle: `Dit zijn uw gegevens die bij ons bekend zijn. {br} Wij kunnen hiermee in geval van nood contact met u opnemen. {br} Wij gebruiken deze gegevens ook om u een bevestiging te sturen.`,

  // vignette details
  activateVignetteDetailsDescription: `Wij hebben de volgende informatie van u nodig:`,
  activateVignetteDetailsFormTitle: "Hoe groot is uw boot?",
  activateVignetteDetailsDocumentTitle: "Foto van uw boot uploaden",
  activateVignetteDetailsBoatPhotoTitle: "Voeg hier een foto van uw boot toe",
  activateVignetteDetailsBoatPhotoTitle2:
    "Voeg hier een foto toe waarop uw volledige vaartuig zichtbaar is",
  activateVignetteDetailsBoatPhotoDescription: `Als uw boot gestolen is kunnen wij deze sneller herkennen.`,
  activateVignetteDetailsDocumentUploadText: "Sleep het bestand in dit vlak of",
  activateVignetteDetailsDocumentSelectFiles: "selecteer een bestand",
  format: "Formaat",
  documentSizeError:
    "Het bestand is te groot. Maximale bestandsgrootte is 7.5MB. Het bestand zal niet worden toegevoegd.",

  // summary
  activateVignetteSummaryDescription:
    "U wilt vignetnummer {vignet_number} activeren.",
  activateVignetteSummaryCompany: "Gegevens van uw bedrijf",
  activateVignetteDoneDescription1: `U ontvangt een e-mail met een bevestiging van de activatie van uw vignet.
  {br} U kunt de samenvatting hieronder downloaden.`,
  activateTransitFinalDetail: `Bel het telefoonnummer 14 020, maandag tot en met vrijdag van 08.00 tot {br} 18.00 uur. Of gebruik het contactformulier op de website. {br} {br}
  Houd uw vignetnummer {vignet} bij de hand.`,

  summaryCheckbox2: `Ik ben op de hoogte van de regels die op het water van Amsterdam gelden, zoals ze staan beschreven in de Binnenhavengeldverordening Pleziervaart 2022 en de Verordening op het binnenwater 2010. "Kijk op <link>https://www.amsterdam.nl/parkeren-verkeer/varen-amsterdam/re-gelingen-verordeningen-water/</link> voor meer informatie.`,

  summaryAgreementDetails: `De gemeente Amsterdam verwerkt uw persoonsgegevens op een {br}
  zorgvuldige en veilige manier in overeenstemming met de geldende wet- {br}
  en regelgeving. Dat betekent onder meer dat uw gegevens niet voor een {br}
  ander doel worden gebruikt dan waarvoor u ze heeft verstrekt. Meer {br}
  informatie over de bescherming van uw persoonsgegevens kunt u vinden {br}
  in de privacyverklaring voor Verkeersmanagement op de gracht en binnenhavengeld.`,
  product: "Product",

  // Restitution
  restitutionFormTitle: `Restitutie formulier`,
  restitutionFormInitials: "Voorletters",
  restitutionFormMiddleName: "Tussenvoegsels",
  restitutionFormLastName: "Achternaam",
  restitutionFormEmail: "E-mail",
  restitutionFormEmailRepeat: "Herhaal E-mail",
  restitutionFormPhoneNumber: "Telefoonnummer",
  restitutionFormVignetteNumber: "Vignetnummer",
  restitutionFormVesselName: "Bootnaam",
  restitutionFormReason: "Reden van opzegging",
  restitutionFormDoneEmail:
    "U ontvangt een e-mail met een bevestiging van uw aanvraag.",
  restitutionFormDoneDownload:
    "U kunt de samenvatting hieronder ook downloaden.",

  restitutionFormErrorMissingHash: "Hash ontbreekt",
  restitutionFormErrorMismatchedHash: "Hash incorrect",
  restitutionFormErrorMissingPhoneNumber: "Vul een telefoonnummer in",
  restitutionFormErrorInvalidPhoneNumber: "Foutief telefoonnummer",
  restitutionFormErrorMissingEmail: "Vul een e-mail adres in",
  restitutionFormErrorMissingEmailValidation: "Vul een e-mail adres in",
  restitutionFormErrorInvalidEmail: "Foutief e-mail adres",
  resittutionFormErrorMismatchedEmail: "E-mail adressen komen niet overeen",
  restitutionFormErrorMissingReason:
    "Vul een reden voor uw restitutie aanvraag in",
  restitutionFormErrorReasonToShort:
    "Reden voor uw restitutie aanvraag is te kort",

  restitutionFormFooterDetail: "Restitutie aanvraag voor",
};

export default dutch;
