export const BASE_URL = "https://backoffice.digitaalvignet.nl";

// passage api
export const PASSAGE_API_BASE = "/api-passage";
export const GET_VIGNETTE_TYPES = `${PASSAGE_API_BASE}/get-vignet-types`;
export const GET_DELIVERY_TYPES = `${PASSAGE_API_BASE}/get-delivery-types`;
export const GET_PAYMENT_TYPES = `${PASSAGE_API_BASE}/get-payment-types`;
export const NEW_VIGNET_PAYMENT = `${PASSAGE_API_BASE}/payment-vignet`;
export const VALIDATE_PASSAGE_OWNER = `${PASSAGE_API_BASE}/validate-owner`;
export const VALIDATE_PASSAGE_VIGNET = `${PASSAGE_API_BASE}/validate-vignet`;
export const NEW_VIGNETTE_GET_COUNTRIES = `${PASSAGE_API_BASE}/get-countries`;
export const NEW_VIGNETTE_SUMMARY = `${PASSAGE_API_BASE}/get-summary`;

// mooring api
export const MOORING_API_BASE = "/api-mooring";
export const CHECK_MOORING_VIGNET = `${MOORING_API_BASE}/check-vignet`;
export const GET_MOORING_PAYMENT_TYPES = `${MOORING_API_BASE}/get-payment-types`;
export const MOORING_PAYMENT = `${MOORING_API_BASE}/payment-mooring`;
export const MOORING_SUMMARY = `${MOORING_API_BASE}/get-summary`;
export const VALIDATE_VOUCHER_CODE = `${MOORING_API_BASE}/validate-voucher`;

// activate api
export const ACTIVATE_API_BASE = "/api-activate";
export const CHECK_ACTIVATE_VIGNET = `${ACTIVATE_API_BASE}/check-vignet`;
export const GET_ACTIVATE_DRIVE_TYPES = `${ACTIVATE_API_BASE}/get-drive-types`;
export const UPDATE_ACTIVATE_VIGNET = `${ACTIVATE_API_BASE}/update-object`;
export const UPDATE_ACTIVATE_OWNER = `${ACTIVATE_API_BASE}/update-owner`;
export const VALIDATE_ACTIVATE_VIGNET = `${ACTIVATE_API_BASE}/validate-object`;
export const VALIDATE_ACTIVATE_OWNER = `${ACTIVATE_API_BASE}/validate-owner`;
export const UPLOAD_ACTIVATE_PHOTOS = `${ACTIVATE_API_BASE}/upload`;
export const ACTIVATE_SAVE_DATA = `${ACTIVATE_API_BASE}/save-data`;
export const ACTIVATE_DOWNLOAD_SUMMARY = `${ACTIVATE_API_BASE}/get-summary`;
export const ACTIVATE_GET_OBJECT_TYPES = `${ACTIVATE_API_BASE}/get-object-types`;

// Pickup locations
export const PICKUP_LOCATIONS =
  "https://backoffice.digitaalvignet.nl/api-passage/get-pickup-locations/nl";
