import { useState } from "react";
import { RESTITUTIE_VIGNETTE_ROUTE } from "../../../constants";
import { useLanguage } from "../../../lang";
import { PersonalInfoFormValidator } from "../FormValidator";
import AgreementConfirmation from "./AgreementConfirmation/AgreementConfirmation";
import RestitutieLayout from "./Layout";

const split = {
  display: "inline-block",
  width: "40%",
  margin: "10px",
};

const Summary = (props) => {
  const formData = PersonalInfoFormValidator.fetchFormData();
  const [language, messages] = useLanguage();

  const [agreement, setAgreement] = useState({
    dataValidity: false,
    endUserLicense: false,
  });

  const dataValidityOnChange = (e) => {
    setAgreement({
      dataValidity: e.target.checked,
      endUserLicense: agreement.endUserLicense,
    });
  };

  const endUserLicenseOnChange = (e) => {
    setAgreement({
      dataValidity: agreement.dataValidity,
      endUserLicense: e.target.checked,
    });
  };

  const dataValidityCallback = {
    setValidity: null,
  };

  const endUserAgreementCallback = {
    setValidity: null,
  };

  PersonalInfoFormValidator.hasUserAgreed = () => {
    return agreement.dataValidity && agreement.endUserLicense;
  };

  PersonalInfoFormValidator.setUserAgreementValidity = () => {
    console.log("v");
    dataValidityCallback.setValidity(agreement.dataValidity);
    endUserAgreementCallback.setValidity(agreement.endUserLicense);
  };

  if (
    !formData ||
    !formData.data ||
    !formData.data.lastName ||
    !formData.data.email
  ) {
    window.location.href = `${RESTITUTIE_VIGNETTE_ROUTE}/personal-info`;
    return <div></div>;
  }

  return (
    <RestitutieLayout
      title={messages.newVignetteStep5}
      subtitle={messages.step1}
    >
      <label style={split}>{messages.restitutionFormInitials}</label>
      <label style={split}>{formData.data.initials}</label>
      <br></br>
      <label style={split}>{messages.restitutionFormMiddleName}</label>
      <label style={split}>{formData.data.middleName}</label>
      <br></br>
      <label style={split}>{messages.restitutionFormLastName}</label>
      <label style={split}>{formData.data.lastName}</label>
      <br></br>
      <label style={split}>{messages.restitutionFormEmail}</label>
      <label style={split}>{formData.data.email}</label>
      <br></br>
      <label style={split}>{messages.phone}</label>
      <label style={split}>{formData.data.phoneNumber}</label>
      <br></br>
      <label style={split}>{messages.restitutionFormVignetteNumber}</label>
      <label style={split}>{formData.data.vignetNumber}</label>
      <br></br>
      <label style={split}>{messages.restitutionFormVesselName}</label>
      <label style={split}>{formData.data.vesselName}</label>
      <br></br>
      <label style={split}>{messages.restitutionFormReason}</label>
      <label style={split}>{formData.data.reason}</label>

      <AgreementConfirmation
        onChange={(e) => {
          dataValidityOnChange(e);
        }}
        value={agreement.dataValidity}
        callback={dataValidityCallback}
      >
        {messages.summaryCheckbox2}
      </AgreementConfirmation>
      <AgreementConfirmation
        onChange={(e) => {
          endUserLicenseOnChange(e);
        }}
        value={agreement.endUserLicense}
        callback={endUserAgreementCallback}
      >
        {messages.summaryCheckbox2}
      </AgreementConfirmation>
    </RestitutieLayout>
  );
};

export default Summary;
