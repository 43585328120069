import moment from "moment";

const english = {
  vignet: "vignette",
  squareMeters: "square meters",
  required: "Required",
  emailRequired: "The e-mail address field is not filled in",
  passwordRequird: "The password field is not filled in",
  invalidEmail:
    "The e-mail address in the field above has been entered incorrectly",
  emailMatch: "Email must match",
  // footer
  contact: "Contact",
  "footer-first-paragraph":
    "Do you have a question or do you need support in completing this form?",
  "footer-second-paragraph": "Then contact the municipality.",
  "footer-third-paragraph":
    "Municipal information number: 14 020 on working days from 8 a.m. to 6 p.m.",
  "footer-fourth-paragraph": "Or send an e-mail via the ",
  mailForm: "mail form",
  "go-to-bhg": "Go to Amsterdam.nl",
  privacy: "Privacy",
  country: "Country",

  openHours: "Opening times",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  closed: "closed",
  delivery: "Delivery",
  totalVignette: "Total Vignettes",
  vignetPin: "Vignette pin",
  vignetteNumber: "Vignette number",
  repeatVignetNumber: "",
  insertion: "Insertion",
  telephone: "Telephone number",
  email: "Email address",
  resume: "summary",
  resumeDetails:
    "You will receive a confirmation email in your mailbox of your application. You can also download the summary.",
  totalAmount: "Total Amount",
  total: "Cost",
  payment: "Payment",

  // steppers
  step1: "Your data",
  step2: "Vignette overview",
  step3: "Your vignette",
  step4: "Summary",
  step5: "Thank you",

  // login screen
  welcome: "Welcome",
  "login-info":
    "With this form you can renew or deregister your sailing vignettes. You must log in to your account for this.",
  login: "Login",
  "email-address": "E-mail address",
  password: "Password",
  "forgot-password": "Forgot your password",
  "forgot-message":
    "Did you forget your password? Enter your email address below. You will receive an e-mail within a few minutes with which you can create a new password.",
  send: "Send",
  resetFeedback:
    "Thank you! You will receive an e-mail within a few minutes with which you can create a new password.",
  backToLogin: "Back to login",
  newPassword: "Create new password",
  passwordInstruction:
    "Enter your new password below. The password must meet these requirements:",
  pwInstcution1: "minimum 8 characters",
  pwInstcution2: "at least 2 digits",
  pwInstcution3: "at least 1 capital letter",
  pwInstcution4: "maximum 24 characters",
  retypePassword: "repeat password",
  passwordMatch: "Passwords must match",
  passwordFailedValidation: "Password does not match validation",
  setPasswordChanged: "Password changed",
  setPasswordChangedMessage:
    "Success! You have successfully changed your password. You can now log in again.",

  // personal info
  logout: "Log out",
  personalInfoHeader: "Sailing vignettes",
  personalInfoTitle: "Your personal information",
  personalInfoSubTitle: "These are your data that are known in our system.",
  initials: "Your initials",
  initialsRequired: "Het veld Uw voorletters is niet ingevuld",
  lastname: "Last name",
  lastnameRequired: "Het veld achternaam is niet ingevuld",
  contactDetails: "Your contact details",
  detailsInfo:
    "Below you can see the contact details we have about you. Are the data incorrect? Then you can change it.",
  change: "Change",
  street: "Street",
  streetRequired: "The street field is not filled in",
  houseNumber: "House number",
  houseNumberRequired: "The house number field is not filled in",
  addition: "Addition",
  notRequired: "not required",
  postcode: "Postal Code",
  postCodeRequired: "The zip code field is not filled in",
  residence: "Residence",
  residenceRequired: "The city field is not filled in",
  phone: "Phone number",
  phoneRequired: "The telephone number field is not filled in",
  phoneNumberValidation: "Telephone number may only consist of numbers",
  repeatEmail: "Repeat e-mail address",
  repeatEmailRequired: "The repeat e-mail address field is not filled in",
  companyDetails: "Company details",
  chamberCommerce: "Chamber of Commerce number",
  company: "Company Name",
  contactPerson: "Contact",
  previous: "Previous",
  next: "Next",
  BSN: "BSN",
  sendNow: "Send Now",

  // vignet overview
  vignetOverview: "Vignette overview",
  vignetOverviewDescription:
    "Below you can see the boats in your name and the corresponding vignettes. You must indicate per boat whether you want to extend or cancel. You will then also see your choice in this overview.",
  boatName: "Name of your boat",
  vignetNumber: "Vignette number",
  renewCancel: "Renew or cancel",
  renew: "Renew",
  smallRenew: "renewed",
  makeChoiceAbove:
    "You have not yet made a choice above, making a choice is mandatory",
  cancel: "Cancel",
  smallCancel: "cancelled",
  noAction: "No action",
  selectChoice:
    "You have not yet made a choice above, making a choice is mandatory",
  mustAgree: "Please check box to agree",

  // cancel vignet
  cancelVignetdescription:
    "Below you can see the vignette that you want to cancel. Also indicate why you want to cancel it.",
  cancelReason: "Reason for cancellation",
  vesselSold: "Boat sold",
  vignetLost: "Vignette has been lost",
  vignetStolen: "Vignette has been stolen",
  others: "Otherwise, namely:",
  cancellationAgreement:
    "I agree that the vignette above will be canceled. I understand that with this boat I will no longer be allowed to moor in Amsterdam.",
  cancellationRequired:
    "You have not ticked the agreement for the conditions above, the tick is mandatory",

  // overview screen
  summaryDetail:
    "Below you will find an overview of your data. Check the data carefully. You can still make changes if something is not right.",
  boatname: "Boat name",
  checkbox1:
    "I have entered all data correctly and completely. I understand that an application via the Internet has the same legal status as an application with a written valid signature.",
  checkbox2:
    "I am aware of the rules that apply to Amsterdam's waterways, as they are described in the Inland Harbor Dues Ordinance Recreational Boating and the Inland Waterways Regulation (available at amsterdam.nl/varen). I agree to these rules when renewing or canceling my vignettes.",
  unsubscribeReason: "Unsubscribe reason",
  verifyInfo: "Verify your information",

  // download screen
  downloadSubTitle: `Thank you for extending or canceling your vignette. We received your notification on ${moment().format(
    "DD/MM/YYYY"
  )} at ${moment().format(
    "HH:mm"
  )}. You will receive a confirmation from us by email within 4 working days.`,
  downloadDetail:
    "You will receive a confirmation email in your mailbox of your application. You can also download the summary.",
  download: "Download ",
  ask: "Questions",
  finalDetail:
    "Call 14020 or use the contact form on the website. Your vignette has a vignette number. When contacting you can mention this vignette number.",

  // extend vignette
  extendTitle: "Renew and pay",
  extendDescription:
    "Below you can see the details of the vignette that you want to renew.",
  vignetDetails: "Details of your vignette",
  boatLength: "Boat length",
  boatWidth: "Boat width",
  length: "Length",
  lengthRequired: "The length field is not filled in",
  width: "Width",
  widthRequired: "The width field is not filled in",
  drive: "Drive",
  marina: "Marina",
  selectDrive: "Select Drive",
  yes: "Yes",
  no: "No",
  vignetCalculation: "Vignette calculation",
  vignetType: "Type of vignette",
  totalArea: "Total area m2",
  totalCost: "Total cost",
  paymentMethod: "Payment method",
  paymentMethod2: "Payment method",
  paymentInstruction:
    "You can pay the amount in one go via iDEAL or in installments. Do you opt for payment in installments? Then you pay by direct debit. The first installment will be debited from your account within 48 hours. The next 3 installments will follow in the consecutive months.",
  pay: "Pay",
  accountNumber: "Account number",
  accountNumberRequired: "The account number field is not filled in",
  accountNumberInvalid:
    "The account number in the field above has been entered incorrectly",
  accountHolder: "Name of account holder",
  accountHolderRequired: "The account holder field is not filled in",
  chooseBank: "Choose your bank",
  selectBank: "select your bank",
  bankPaymentErrorTitle: "Your payment has failed",
  bankPaymentErrorDescription:
    "The vignette has not been renewed. Change your payment method and try again.",
  documents: "Documents",
  NononTheFingHeader: "Add document berth",
  NononTheFingDescription:
    "Is your boat in a marina? Then you must upload a marina invoice. This should contain the name and address of the marina. Is your boat located outside of Amsterdam, but not in a marina? Then you must upload a photo of the berth with the boat moored visibly. And you must upload a map of the location where your boat is located.",
  onThefingHeader: "Add document waiver",
  onThefindDescription:
    "Is your boat 10 meters or longer? Then you must have an exemption or exemption for this. You must upload this exemption here.",
  uploadText: "Drag the files into this area or",
  selectFiles: "select files",
  marinaQuestion: "Is your boat located in a marina or outside Amsterdam?",
  documentUpload: "Documents uploaded",
  cancelUpload: "Cancel",
  milieuTitle: "Note: you will receive an environmental (green) vignette.",
  milieuDescription:
    "Your data shows that you are entitled to an environmental (green) vignette. This vignette is only valid if your boat has an electric motor or no motor at all.",
  hybridTitle: "Note: you will receive a hybrid (yellow) vignette.",
  hybridDescription:
    "Your data shows that you are entitled to a hybrid (yellow) vignette. This means that your boat has an electric motor and a diesel or petrol engine. Are you sailing on Amsterdam's inland waterways? Then you may only use the electric motor. You may not charge your electric motor with a (fuel) generator while sailing.",
  abonmentTitle: "Note: you will receive a subscription (white) vignette.",
  abonmentDescription:
    "Your data shows that you are entitled to a subscription (white) vignette. This means that your boat is in a marina or outside of Amsterdam. With this vignette you are not allowed to moor on the Amsterdam inland waterways between 11 p.m. and 6 a.m.",
  regulierTitle: "Note: you will receive a regular (red) vignette.",
  regulierDescription:
    "Your data shows that you are entitled to a regular (red) vignette. That means your boat has a diesel or gasoline engine. With this vignette you can sail and moor on the Amsterdam inland waterways. From 2025, you may only sail emission-free in the center of Amsterdam.",
  accountNumberAgreement:
    "I give the municipality of Amsterdam permission to debit the costs of my vignette from my bank account in 4 installments.",
  requiredDocument: "You must upload require document",
  toClear: "To clear",
  mustSelectDrive: "You must select a drive type",
  paymentSuccessfulTitle: "Your payment has been successfully completed",
  paymentSuccessfulDescription:
    "The vignette has been extended. Do you have multiple vignettes? Then you will automatically return to the screen with your overview.",
  vesselType: "Vessel type",
  boatName2: "Boat name",

  // TRANSIT VIGNETTES
  //   home page
  homePageTitle: "Transit vignette",
  homePageSubTitle: "Transit vignette Municipality of Amsterdam",
  homePageDescription: `To be able to sail in Amsterdam, you must have an activated vignette on your boat {br}
  to have. This can be an Inland Harbor Dues vignette. If you don't have this, {br}
  you need a Transit vignette.`,
  transitEmailRequired:
    "This is not a valid email address. An email address consists of @ and a domain name. For example: name@domain.nl",
  transitEmailNoMatch: "The email addresses are not the same.",
  transitTelephoneNoMatch: "The phone numbers are not the same",
  transitVignetteNoMatch: "The vignette numbers are not the same",
  transitAgreeMessage: "You must agree to the statement. Check if you agree.",
  transitPaymentFailedTitle: "Payment failed.",
  transitPaymentFailedDescription:
    "Please try again or choose a different payment method.",

  homePageActivateVignetTitle: "Activate vignette",
  homePageActivateVignetDescription: `Do you already have a transit vignette? {br} Then you can activate your vignette here.`,
  homePageActivateButton: "Activate your vignette",

  homePageRequestVignetTitle: "Request a new vignette",
  homePageRequestVignetDescription: `Do you already have your Transit vignette? {br}
  Request one so that you can sail in Amsterdam.`,
  homePageRequestButton: "Request a new vignette",

  homePageMoorVignetTitle: "Vignette for 24 hours mooring",
  homePageMoorVignetDescription: `Do you have a transit vignette, but still want to moor? {br}
  Then request a 24-hour mooring. This is immediately valid after purchase`,
  homePageMoorButton: "Request a 24 hour mooring",

  // --- request day vignette ----
  dayVignetteTitle: "Request and activate 24 hour mooring",
  dayStep1: "Enter vignette number",
  dayStep2: "Moor product for 24 hours",
  dayStep3: "Contact information",
  dayStep4: "Pay",
  dayStep5: "Summary of your application",
  dayStep6: "Thank you",

  transitDownloadSubTitle: `You will receive an email confirming your request. {br} You can also download the summary below.`,
  transitFinalDetail: `Call the telephone number 14 020, Monday to Friday from 8 am to 6 pm. Or use the contact form on the website. {br} {br}
  We have given a number to your request. {br}
  The number is: {vignet}. In case of contact you can mention this number.`,

  // vignet verification
  dayVignetteVerificationTitle: "Enter number of Transit vignette",
  dayVignetteDescription: `You want to moor in the municipality of Amsterdam for 24 hours. You can extend your Transit vignette {br} with this option.`,
  dayVignetteNumber: "Number of your Transit vignette",
  repeatvignetteNumber: "Repeat number of your vignette",

  // payment guide
  dayVignettePaymentGuideTitle: "Mooring for 24 hours",
  dayVignettePaymentGuideDescription: `After receiving the confirmation email, you may {br}
  Mooring for 24 hours in the municipality of Amsterdam.`,
  dayVignettePaymentGuideItem1:
    "Mooring for 24 hours is immediate and valid for 24 hours.",
  dayVignettePaymentGuideItem2: "The costs are € 30,-.",
  dayVignettePaymentGuideInfo: `If you have a voucher, you can pay with it.`,

  // personal info
  dayVignettePersonalDescription: `Enter your contact details here to receive a confirmation.`,
  repeatTelephone: "Repeat Telephone Number",
  dayVignetteFirstName: "First name",

  // payment choice
  dayVignettePaymentChoiceTitle: "Choose payment method",
  dayVignettePaymentChoiceDescription: `You can pay the amount via iDEAL or Credit card.`,

  // summary
  dayVignetteSummaryDescription: "Below are your details for 24 hours mooring.",
  dayVignettePaymentGuideLabel: "Mooring for 24 hours",
  dayVignettePaymentGuideLabel1: "Valid from",
  dayVignettePaymentGuideLabel2: "Valid until",

  // new vignette
  newVignetteTitle: "Request a new vignette",
  newVignetteStep1: "Choose Vignette",
  newVignetteStep2: "Fill in data",
  newVignetteStep3: "Delivery options",
  newVignetteStep4: "Pay",
  newVignetteStep5: "Summary of your application",
  newVignetteStep6: "Thank you",

  // choose vignette
  newVignetteChooseTitle: "Make your choice",
  newVignetteChoseVignetteDescription: `You can buy a Transit vignette with or without the option
  {br} to be able to moor in Amsterdam for 24 hours later this year. If you buy it now it costs 10 euros.
  {br} If you decide later to buy a 24 hour mooring, it will cost 30 euros.`,
  newVignetteChooseVignetteSort: "Vignette type",
  newVignetteChooseVignetteSortLabel1:
    "Sailing vigent <b>without</b> mooring option € 20, -",
  newVignetteChooseVignetteSortLabel2:
    "Sailing vigent <b>with</b> mooring option € 30, -",
  newVignetteChooseVignetteDropDownLabel: "Number of vignettes",
  newVignetteChooseVignetteDropDownDescription:
    "You can request a maximum of 10 vignettes (all with or all without mooring for 24 hours).",
  newVignetteChoseVignetteInfoLabel: "Note: Only post shipping possible",
  newVignetteChoseVignetteInfoDescription1:
    "If you purchase more than one sticker, it can only be sent by regular mail",
  newVignetteChoseVignetteInfoDescription2:
    "Collection or urgent delivery is not possible.",

  // personal info
  newVignettePersonalInfoTitle: "Your personal information",
  newVignettePersonalInfoDescription: "Please enter your details.",
  newVignettePersonalInfoCheckboxLabel:
    "I request a vignette on behalf of a company.",

  // delivery options
  newVignetteDeliveryOptionsTitle: "Home delivery",
  newVignetteDeliveryOptionsTakeAway: "Take Away",
  newVignetteDeliveryOptionsSubtitle: "Short explanation 4 terms",
  newVignetteDeliveryOptionsHomeTitle: "Home delivery",
  newVignetteDeliveryOptionsCollectionPoints: "Collection points (free)",
  newVignetteDeliveryOptionsCollectionLabel: `<b> I pick up my vignette myself at one of the collection points below. </b>`,
  newVignetteDeliveryOptionsCollectionsMore: `You will receive a receipt. You can use this to pick up your vignette directly at a {br} address below. You do not have to make an appointment for this.`,
  newVignetteDeliveryOptionsCharge: "Delivery charge",

  // summary
  newVignetteSummaryDescription: `We received your request at ${moment().format(
    "DD MMMM YY"
  )}.`,
  newVignetteSummaryInfo: `You will receive an email with a confirmation of your request. {br}
  You can also download the summary below.`,
  newVignetteTransitFinalDetail: `Call the telephone number 14 020, Monday to Friday from 8 a.m. {br} to 6 p.m. Or use the contact form on the website. {br} {br}
  Have your vignette number {vignet} to hand.`,

  // activate vignette
  activateVignetteStep1: "Enter the vignette number",
  activateVignetteStep2: "Your data",
  activateVignetteStep3: "Details of your boat",
  activateVignetteTitle: "Activate transit vignette",
  activateVignetteSubtitle: "Enter the vignette number and activation code",
  activateVignetteChoices: "Choose vignette type",
  activateVignetteChoiceLabel1:
    "Transit vignette purchased from the Municipality of Amsterdam.",
  activateVignetteChoiceLabel2: "Vignette of another municipality or province.",
  activateVignetteDescription1: `Enter the new vignette number and activation code below. {br}
  You will find the vignette number on the physical vignette. The Amsterdam {br} transit vignette number starts with AM and has 5 digits. {br}
  You have received the activation code in an email.`,
  activateVignetteDescription2: `Enter the vignette number below. {br} You will find the vignette number on the physical vignette.`,
  activateVignetteCode: "Activation code",
  activateVignetteFormDetails: `Do you have Vignettes from outside the Municipality of Amsterdam?
    {br} Then enter your Vignette number again at vignette pin.`,
  activateVignetteNotificationTitle:
    "Please remove your 2019 or earlier vignettes from your boat.",
  activateVignetteNotificationDescription: `Do you have a vignette of another municipality or province.
    {br} Then enter your Vignette number twice.`,
  vignettePrivacyStatement: `De gemeente Amsterdam gaat zorgvuldig en veilig met uw persoonsgegevens om. Wij houden ons daarbij aan de privacy-  wetten en -regels. Dat betekent bijvoorbeeld dat wij uw gegevens alleen voor deze aanvraag gebruiken en niet voor iets  anders. Meer informatie vindt u in de <link>privacyverklaring Binnenhavengeld</link>.`,
  documentSizeError: "Het document is te groot",
  // personal info
  activateVignettePersonalSubtitle: `Please enter your details. We can then contact you in an {br} emergency. We also use this information to send you a confirmation.`,

  // vignette details
  activateVignetteDetailsDescription: `We need the following information from you:`,
  activateVignetteDetailsFormTitle: "How big is your boat?",
  activateVignetteDetailsDocumentTitle: "Upload photo of your boat",
  activateVignetteDetailsBoatPhotoTitle: "Add a photo of your boat here",
  activateVignetteDetailsBoatPhotoTitle2:
    "Add a photo here showing your complete vessel",
  activateVignetteDetailsBoatPhotoDescription: `If your boat is stolen, we can recognize it faster.`,
  activateVignetteDetailsDocumentUploadText: "Drag the files into this area or",
  activateVignetteDetailsDocumentSelectFiles: "select files",
  format: "Format",

  // vignette summary
  activateVignetteSummaryDescription:
    "You want to activate the vignette number {vignet_number}.",
  activateVignetteSummaryCompany: "Information about your company",
  activateVignetteDoneDescription1: `You will receive an email confirming the activation of your vignette.
  {br} You can also download the summary below.`,
  activateTransitFinalDetail: `Call the telephone number 14 020, Monday to Friday from 8 a.m. {br} to 6 p.m. Or use the contact form on the website. {br} {br}
  Have your vignette number {vignet} to hand.`,

  summaryCheckbox2: `I am aware of the rules that apply on Amsterdam's waterways, as they are described in the Inland Harbor Dues Ordinance Recreational Boating 2022 and the Inland Waterways Regulation 2010.
  of my vignettes I agree with these rules.
  Visit <link>amsterdam.nl/varen</link> for more information.`,

  summaryAgreementDetails: `The municipality of Amsterdam processes your personal data on a {br}
  careful and safe manner in accordance with the applicable law- {br}
  and regulations. This means, among other things, that your data is not for a {br}
  used for a purpose other than that for which you provided them. More {br}
  you can find information about the protection of your personal data {br}
  in the privacy statement for Traffic management on the canal and inland harbor dues.`,
  product: "Product",

  // Restitution
  restitutionFormTitle: `Restitution form`,
  restitutionFormInitials: "Initials",
  restitutionFormMiddleName: "Preposition",
  restitutionFormLastName: "Last name",
  restitutionFormEmail: "E-mail",
  restitutionFormEmailRepeat: "Repeast E-mail",
  restitutionFormPhoneNumber: "Phone number",
  restitutionFormVignetteNumber: "Vignette number",
  restitutionFormVesselName: "Vessel name",
  restitutionFormReason: "Reason for cancelation",
  restitutionFormDoneEmail:
    "You will receive a confirmation email about your restitution request.",
  restitutionFormDoneDownload:
    "You can also download a summary of your request below.",

  restitutionFormErrorMissingHash: "Hash missing",
  restitutionFormErrorMismatchedHash: "Hash incorrect",
  restitutionFormErrorMissingPhoneNumber: "Missing phone number",
  restitutionFormErrorInvalidPhoneNumber: "Invalid phone number",
  restitutionFormErrorMissingEmail: "Missing e-mail address",
  restitutionFormErrorMissingEmailValidation: "Vul een e-mail adres in",
  restitutionFormErrorInvalidEmail: "Invalid e-mail address",
  resittutionFormErrorMismatchedEmail: "E-mail addresses not the same",
  restitutionFormErrorMissingReason:
    "Fill in the reason for your restitution request",
  restitutionFormErrorReasonToShort:
    "Reason for your restitution request is too short",
  restitutionFormFooterDetail: "Restitution request for",
};

export default english;
