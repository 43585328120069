import { useEffect } from "react";
import { useLanguage } from "../../../lang";
import { PersonalInfoFormValidator } from "../FormValidator";
import RestitutieLayout from "./Layout";

const labelInputBlock = {
  display: "block",
  marginBottom: "25px",
};

const equalSpacing = {
  display: "inline-block",
  width: "25%",
  height: "40px",
  marginRight: "25px",
};

const lineSpacing = {
  display: "inline-block",
  width: "25%",
  marginRight: "25px",
};

const textAreaStyling = {
  width: "35%",
  height: "150px",
  marginRight: "20px",
};

const errorText = {
  color: "#EC0000",
};

let fieldValues = {};

const applyValidationStyling = (
  id,
  validation,
  isRecursiveCall,
  ignoreIfEmpty
) => {
  const target = document.getElementById(id);
  if (!target) {
    console.error("Invalid target:", id, target);
    return;
  }
  const targetErrorText = document.getElementById(id + "-" + "error-text");
  if (id == "email" && !isRecursiveCall) {
    applyValidationStyling("emailValidation", validation, true);
  } else if (id == "emailValidation" && !isRecursiveCall) {
    applyValidationStyling("email", validation, true);
  }
  if (validation.isValid) {
    target.style.border = "1px solid grey";
    target.style.backgroundColor = "white";
    if (targetErrorText) {
      targetErrorText.innerHTML = validation.note || "";
      targetErrorText.style.color = "black";
    }
  } else {
    if (PersonalInfoFormValidator.isValueEmpty(target.value) && ignoreIfEmpty) {
      return;
    }
    target.style.border = "2px solid #EC0000";
    target.style.backgroundColor = "white";
    if (targetErrorText) {
      targetErrorText.innerHTML = validation.note || "Invalid";
      targetErrorText.style.color = "#EC0000";
    }
  }
};

const isFieldChangeValid = (id) => {
  if (id == "email" || id == "emailValidation") {
    return PersonalInfoFormValidator.isEmailValid();
  }
  if (id == "phoneNumber") {
    return PersonalInfoFormValidator.isPhoneNumberValid();
  }
  if (id == "reason") {
    return PersonalInfoFormValidator.isReasonValid();
  }
  return { isValid: !PersonalInfoFormValidator.isFieldEmpty(id) };
};

const areFieldsValid = (ignoreIfEmpty = true) => {
  applyValidationStyling(
    "initials",
    isFieldChangeValid("initials"),
    false,
    ignoreIfEmpty
  );
  applyValidationStyling(
    "lastName",
    isFieldChangeValid("lastName"),
    false,
    ignoreIfEmpty
  );
  applyValidationStyling(
    "vesselName",
    isFieldChangeValid("vesselName"),
    false,
    ignoreIfEmpty
  );
  applyValidationStyling(
    "vignetNumber",
    isFieldChangeValid("vignetNumber"),
    false,
    ignoreIfEmpty
  );

  applyValidationStyling(
    "email",
    isFieldChangeValid("email"),
    true,
    ignoreIfEmpty
  );
  applyValidationStyling(
    "emailValidation",
    isFieldChangeValid("emailValidation"),
    true,
    ignoreIfEmpty
  );
  applyValidationStyling(
    "phoneNumber",
    isFieldChangeValid("phoneNumber"),
    false,
    ignoreIfEmpty
  );
  applyValidationStyling(
    "reason",
    isFieldChangeValid("reason"),
    false,
    ignoreIfEmpty
  );
};

const handleFieldChange = (e) => {
  const id = e.target.id;
  const value = e.target.value;
  const formData = PersonalInfoFormValidator.fetchFormData();
  formData.data[id] = value;
  PersonalInfoFormValidator.dumpFormData(formData);
  formData.meta.hash = PersonalInfoFormValidator.generateHash();
  PersonalInfoFormValidator.dumpFormData(formData);
  const validation = isFieldChangeValid(id, value, e);
  applyValidationStyling(id, validation, false, false);
};

const PersonalInfo = () => {
  if (!document.referrer.endsWith("restitutie/summary")) {
    window.localStorage.clear();
  }
  PersonalInfoFormValidator.setCallback(() => {
    areFieldsValid(false);
  });
  const formData = PersonalInfoFormValidator.fetchFormData().data;
  useEffect(() => {
    areFieldsValid();
  });

  const [language, messages] = useLanguage();
  PersonalInfoFormValidator.lang = messages;
  return (
    <RestitutieLayout
      title={messages.step1}
      subtitle={messages.newVignettePersonalInfoDescription}
    >
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormInitials}</label>
        <br></br>
        <input
          id="initials"
          defaultValue={formData.initials}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
      </div>
      <div>
        <label style={lineSpacing}>{messages.restitutionFormMiddleName}</label>
        <label style={lineSpacing}>{messages.restitutionFormLastName}</label>
        <br></br>
        <input
          id="middleName"
          defaultValue={formData.middleName}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
        <input
          id="lastName"
          defaultValue={formData.lastName}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
      </div>
      <br></br>
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormEmail}</label>
        <br></br>
        <input
          id="email"
          defaultValue={formData.email}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
        <label style={errorText} id="email-error-text"></label>
      </div>
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormEmailRepeat}</label>
        <br></br>
        <input
          id="emailValidation"
          defaultValue={formData.emailValidation}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
        <label style={errorText} id="emailValidation-error-text"></label>
      </div>
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormPhoneNumber}</label>
        <br></br>
        <input
          id="phoneNumber"
          defaultValue={formData.phoneNumber}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
        <label style={errorText} id="phoneNumber-error-text"></label>
      </div>
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormVignetteNumber}</label>
        <br></br>
        <input
          id="vignetNumber"
          defaultValue={formData.vignetNumber}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
      </div>
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormVesselName}</label>
        <br></br>
        <input
          id="vesselName"
          defaultValue={formData.vesselName}
          onChange={handleFieldChange}
          style={equalSpacing}
          type="text"
        ></input>
      </div>
      <div style={labelInputBlock}>
        <label>{messages.restitutionFormReason}</label>
        <br></br>
        <textarea
          id="reason"
          defaultValue={formData.reason}
          onChange={handleFieldChange}
          style={textAreaStyling}
          type="text"
        ></textarea>
        <label style={errorText} id="reason-error-text"></label>
      </div>
    </RestitutieLayout>
  );
};

export default PersonalInfo;
